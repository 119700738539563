import PropTypes from 'prop-types';
import { FaCoins, FaEye } from 'react-icons/fa';
import { Alert, Card, CardBody, Col, Row, Spinner } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { systemCurrencyLabel } from '../../../../../../lib/currency';
import { useFetchAdCreditAnalytics, useFetchAdViewsAnalytics } from '../../../../../../api-hooks/analytics/ads';

export const AdvertisingDashboardProgress = ({ adCampaignId }) => {
	const { t } = useTranslation();

	const {
		data: adViewsAnalytics,
		isLoading: isAdViewsAnalyticsLoading,
		isError: isAdViewsAnalyticsError,
	} = useFetchAdViewsAnalytics(adCampaignId);

	const {
		data: adCreditsAnalytics,
		isLoading: isAdCreditsAnalyticsLoading,
		isError: isAdCreditsAnalyticsError,
	} = useFetchAdCreditAnalytics(adCampaignId);

	if (isAdViewsAnalyticsError || isAdCreditsAnalyticsError) {
		return (
			<Alert color="danger">{t('Global.error')}</Alert>
		);
	}

	if (isAdViewsAnalyticsLoading || isAdCreditsAnalyticsLoading) {
		return (
			<Spinner />
		);
	}

	return adViewsAnalytics && adCreditsAnalytics ? (
		<Row className="mb-5">
			<Col md={6}>
				<Card className="h-100">
					<CardBody className="d-flex justify-content-center flex-column align-items-center text-black text-center py-5 px-5">
						<h4>{adViewsAnalytics.views} / {adViewsAnalytics.viewTarget}</h4>
						<h5 className="text-secondary"><FaEye className="mr-1" />
							{t('AdCampaings.Ad.views')}
						</h5>
					</CardBody>
				</Card>
			</Col>
			<Col md={6}>
				<Card className="h-100">
					<CardBody className="d-flex justify-content-center flex-column align-items-center text-black text-center py-5 px-5">
						<h4>
							{adCreditsAnalytics.issuedChips} / {adCreditsAnalytics.totalChips}
						</h4>
						<h5 className="text-secondary"><FaCoins className="mr-1" />
							{t('AdCampaings.Ad.chipsIssued', { systemCurrencyLabel })}
						</h5>
					</CardBody>
				</Card>
			</Col>
		</Row>
	) : null;
};

AdvertisingDashboardProgress.propTypes = {
	adCampaignId: PropTypes.string,
};

AdvertisingDashboardProgress.defaultProps = {
	adCampaignId: undefined,
};
