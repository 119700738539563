import PropTypes from 'prop-types';
import { useCallback, useEffect, useRef } from 'react';
import { Button, Popover, PopoverBody } from 'reactstrap';

import clsx from 'clsx';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import TimePicker from 'react-time-picker';
import { format } from 'fecha';

import { useTranslation } from 'react-i18next';
import { FaCalendarAlt, FaTimes } from 'react-icons/fa';
import { ButtonPill } from '../../Button';
import { useOutsideClickEvent } from '../../../lib/click';
import './StartTimePicker.scss';

export const StudioScheduleSettingsFormStartTimePicker = ({
	onChange,
	open,
	startTime,
	toggle,
	calendarName,
}) => {
	const { t } = useTranslation();
	const ref = useRef();

	useEffect(() => {
		if (open) {
			const handleClick = () => { toggle(); };

			document.addEventListener('click', handleClick);
			return () => {
				document.removeEventListener('click', handleClick);
			};
		}
		return undefined;
	}, [open, toggle]);

	const stopPropagation = (e) => {
		e.stopPropagation();
		e.nativeEvent.stopImmediatePropagation();
	};

	const closeDatePicker = useCallback(() => {
		if (open) toggle();
	}, [open, toggle]);

	useOutsideClickEvent(closeDatePicker, ref);

	return (
		<div
			className="StudioScheduleSettingsFormStartTimePicker d-flex align-items-center flex-shrink-0"
		>
			<span className="mr-1">
				{format(new Date(startTime), t('Global.DateFormat.dateAndTime'))}
			</span>
			<Button
				className={clsx(
					'd-30 p-0 flex-shrink-0 d-flex align-items-center justify-content-center content-dark',
					{ 'bg-primary': open },
				)}
				color="link"
				id={calendarName}
				onClick={toggle}
				type="button"
			>
				<FaCalendarAlt
					className="cursor-pointer"
				/>
			</Button>
			<Popover
				className="StudioScheduleSettingsFormStartTimePicker_popover"
				onClick={stopPropagation}
				onMouseDown={stopPropagation}
				isOpen={open}
				placement="auto"
				target={calendarName}
				toggle={toggle}
			>
				<div ref={ref}>
					<PopoverBody className="position-relative bg-popover content-popover">
						<Button
							color="link"
							className="position-absolute top-0 right-0 d-30 p-0 text-secondary"
							onClick={toggle}
						>
							<FaTimes />
						</Button>
						<div className="mt-2">
							<Calendar
								onChange={(value) => {
									const currentDate = startTime || new Date();
									value.setHours(currentDate.getHours(), currentDate.getMinutes());
									onChange(value);
								}}
								value={startTime || new Date()}
							/>
							<div className="StudioScheduleSettingsFormStartTimePicker_TimePicker d-flex justify-content-between my-1 align-items-center">
								<TimePicker
									className="ml-2 rounded border-secondary border"
									clearIcon={null}
									onChange={(value) => {
										if (!value) return;
										const numbers = value.split(':');
										const currentDate = startTime || new Date();
										const newDate = new Date(currentDate.toString());
										newDate.setHours(parseInt(numbers[0], 10), parseInt(numbers[1], 10));
										onChange(newDate);
									}}
									value={startTime || new Date()}
									disableClock
									autoFocus
								/>
								<ButtonPill
									className="btn-pill font-weight-bold me-2"
									color="primary"
									onClick={closeDatePicker}
									size="sm"
								>
									{t('SettingsForm.StartTimePicker.save')}
								</ButtonPill>
							</div>
						</div>
					</PopoverBody>
				</div>
			</Popover>

		</div>
	);
};

StudioScheduleSettingsFormStartTimePicker.propTypes = {
	startTime: PropTypes.shape(),
	onChange: PropTypes.func.isRequired,
	open: PropTypes.bool,
	toggle: PropTypes.func.isRequired,
	calendarName: PropTypes.string,
};

StudioScheduleSettingsFormStartTimePicker.defaultProps = {
	startTime: new Date(),
	open: false,
	calendarName: 'OpenCalendar',
};
