import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { ResourceAccessCard } from './Card';
import { currencyLabel } from '../../lib/currency';

export const ResourceAccessPiPoints = ({ resourceType }) => {
	const { t } = useTranslation();

	let label = '';
	if (resourceType === 'STUDIO') {
		label = t('ResourceAccess.PiPoints.meeting');
	} else if (resourceType === 'VIDEO') {
		label = t('ResourceAccess.PiPoints.video');
	}

	return (
		<ResourceAccessCard>
			<h3>
				{t('ResourceAccess.PiPoints.this')} {label} {t('ResourceAccess.PiPoints.isPrivate')}.
			</h3>
			<p>
				{t('ResourceAccess.PiPoints.noEnoughPi', { currencyLabel })} {label}
			</p>
		</ResourceAccessCard>
	);
};

ResourceAccessPiPoints.propTypes = {
	resourceType: PropTypes.string.isRequired,
};
