import { useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useAsyncCallback } from 'react-async-hook';
import {
	Button,
	Input,
	FormGroup,
	Label,
	Alert,
	Row,
	Col,
	Popover,
	PopoverBody,
} from 'reactstrap';
import { Form, Field, FieldError } from 'react-jsonschema-form-validation';
import { useTranslation } from 'react-i18next';

import { FaInfoCircle } from 'react-icons/fa';
import { createDonation } from '../../../api/channel/donation';
import { FormModal } from '../../Form/Modal';
import FormSubmit from '../../Form/Submit';
import { ButtonPill } from '../../Button';
import { Currency, currencyLabel, FiatCurrency, FiatCurrencyLabel } from '../../../lib/currency';
import { OrderBundleType } from '../../../views/Checkout/Paypal/Order/Button';
import { useCalculatePoints } from '../../../lib/useCalculatePoints';
import { useProfile } from '../../Profile/ProfileContext';
import { usePoints } from '../../Membership/Points';
import { useCalculatePrice } from '../../../lib/useCalculatePrice';
import { PaypalCheckout } from '../../../views/Checkout/Paypal/Checkout';
import { FormLabel } from '../../Form/Label';

const schema = {
	type: 'object',
	additionalProperties: false,
	properties: {
		amount: {
			type: 'number',
			minimum: 0,
		},
		currency: {
			type: 'string',
			enum: [...Object.values(FiatCurrency), Currency.PI_POINTS],
		},
		includeFee: {
			type: 'boolean',
		},
	},
	required: ['amount', 'currency'],
};

const DonateModal = ({
	isOpen,
	channel,
	toggle,
}) => {
	const { profile } = useProfile();

	const { t } = useTranslation();
	const [formData, setFormData] = useState({
		amount: 0,
		currency: profile?.preferences?.currency ?? FiatCurrency.USD,
		includeFee: false,
	});
	const [showPopover, setShowPopover] = useState(false);
	const { balance } = usePoints();

	const isPointsDonation = useMemo(() => formData.currency === Currency.PI_POINTS, [formData]);

	const calculatePoints = useCalculatePoints(
		OrderBundleType.PI_POINTS,
		!isPointsDonation ? formData.currency : FiatCurrency.USD,
	);
	const calculatePrice = useCalculatePrice(
		OrderBundleType.PI_POINTS,
		!isPointsDonation ? formData.currency : FiatCurrency.USD,
	);

	const handleChange = useCallback((data) => {
		setFormData(data);
	}, []);

	const onSubmit = async (amount, includeFee) => {
		await createDonation(
			channel._id,
			{ amount, includeFee },
		);
	};

	const handleSubmitAsync = useAsyncCallback(async () => (
		onSubmit(formData.amount, formData.includeFee)
	));
	const { error, reset } = handleSubmitAsync;
	if (error) console.error(error);

	const isSuccess = handleSubmitAsync.status === 'success';
	const unexpectedErrorMsg = t('Donate.Modal.error');
	const errorMessage = error && (error.response?.data?.message || unexpectedErrorMsg);
	const errorReason = error?.response?.data?.reason;
	const isInsufficientFoundsError = errorReason === 'INSUFFICIENT_FUNDS';
	const isGeneralError = error && !isInsufficientFoundsError;

	const remainingPiPoints = useMemo(() => (
		balance - formData.amount
	), [balance, formData]);

	const requiredPiPoints = useMemo(() => (
		formData.amount - balance
	), [balance, formData]);

	const insufficientFunds = remainingPiPoints < 0;

	return (
		<FormModal
			contentClassName="form-dark"
			isOpen={isOpen}
			showCloseButton={!handleSubmitAsync.loading}
			toggle={toggle}
		>
			<h1 className="display-4 mb-5 font-weight-bold">{t('Donate.Modal.donateTo')} {channel.nickname}</h1>
			<Form
				data={formData}
				onChange={handleChange}
				onSubmit={handleSubmitAsync.execute}
				schema={schema}
			>
				<Row>
					<Col>
						<FormGroup>
							<Label for="amount">
								{t('Donate.Modal.amount')}
							</Label>
							<Field
								component={Input}
								type="number"
								name="amount"
								id="amount"
								placeholder="20"
								min={1}
								value={formData.amount}
							/>
							<FieldError name="amount" />
						</FormGroup>
					</Col>
					<Col>
						<FormGroup>
							<Label for="currency">{t('Donate.Modal.currency')}</Label>
							<Field
								component={Input}
								type="select"
								name="currency"
								className=""
								value={formData.currency}
							>
								{Object.keys(FiatCurrency).map((currencyKey) => (
									<option key={`option-${currencyKey}`} value={FiatCurrency[currencyKey]}>
										{FiatCurrencyLabel[currencyKey]}
									</option>
								))}
								<option value={Currency.PI_POINTS}>
									{t('Donate.Modal.existingCurrency')}
								</option>
							</Field>
						</FormGroup>
					</Col>
				</Row>
				<Row>
					<Col>
						<FormGroup id="IncludeFeeContainer">
							<FormLabel>
								<Field
									checked={formData.includeFee}
									component={Input}
									name="includeFee"
									type="checkbox"
								/>
								<span className="ml-2">{t('Donate.Modal.includeFee')}</span>
							</FormLabel>
							<span
								onMouseEnter={() => setShowPopover(true)}
								onMouseLeave={() => setShowPopover(false)}
								id="IncludeFeeIcon"
							>
								<FaInfoCircle
									size={12}
									className="ml-1"
								/>
							</span>
							<Popover
								placement="bottom"
								isOpen={showPopover}
								target="IncludeFeeIcon"
								container="IncludeFeeContainer"
							>
								<PopoverBody className="text-black">
									{t('Donate.Modal.fee')}
								</PopoverBody>
							</Popover>
							<FieldError name="includeFee" />
						</FormGroup>
					</Col>
				</Row>
				<footer className="mt-5">
					{!isSuccess && isPointsDonation && (
						<div>
							{!insufficientFunds ? (
								<>
									<p className="mb-0">{t('Donate.Modal.available')}: <span>{balance} {currencyLabel}</span></p>
									<p className="mb-0">{t('Donate.Modal.donation')}: <span>{formData.amount} {currencyLabel}</span></p>
									<p className="mb-0">{t('Donate.Modal.remaining')}: <span className="text-success">{remainingPiPoints} {currencyLabel}</span></p>
								</>
							) : null}
						</div>
					)}
					{isGeneralError && <Alert color="danger">{errorMessage}</Alert>}
					{!isPointsDonation && (
						<div>
							<PaypalCheckout
								amount={calculatePoints(formData.amount)}
								orderBundleType={OrderBundleType.DONATION}
								channelId={channel._id}
								includeDonationFee={formData.includeFee}
							/>
						</div>
					)}
					{(isInsufficientFoundsError || (insufficientFunds && !isSuccess)) && isPointsDonation ? (
						<div color="neutral">
							<strong className="d-block mb-2">
								{t('Donate.Modal.insufficientPoints')}{' '}
								<span className="text-danger">
									{`${calculatePrice(requiredPiPoints)} ${FiatCurrencyLabel[FiatCurrency.USD]}`}  ({requiredPiPoints} {t('Donate.Modal.piPoints')})
								</span>
							</strong>
							<PaypalCheckout
								amount={requiredPiPoints}
								orderBundleType={OrderBundleType.PI_POINTS}
							/>
						</div>
					) : null}
					{isSuccess && (
						<Alert color="success">
							{t('Donate.Modal.donationSuccessfull')} {channel.nickname}
						</Alert>
					)}
					<div className="d-flex">
						{!isSuccess && isPointsDonation && (
							<>
								<Button
									color="link"
									disabled={handleSubmitAsync.loading}
									onClick={toggle}
								>
									{t('Donate.Modal.cancel')}
								</Button>
								{!insufficientFunds && (
									<FormSubmit className="ml-auto" loading={handleSubmitAsync.loading}>
										{t('Donate.Modal.donate')}
									</FormSubmit>
								)}
							</>
						)}
						{isSuccess && (
							<ButtonPill
								className="ml-auto"
								color="info"
								onClick={toggle}
							>
								{t('Donate.Modal.close')}
							</ButtonPill>
						)}
					</div>
				</footer>
			</Form>
		</FormModal>
	);
};

DonateModal.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	channel: PropTypes.shape({
		_id: PropTypes.string.isRequired,
		nickname: PropTypes.string.isRequired,
	}).isRequired,
	toggle: PropTypes.func.isRequired,
};

export default DonateModal;
