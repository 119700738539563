import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { systemCurrencyLabel } from '../../../lib/currency';

const NotificationToastDonation = ({
	notification,
}) => {
	const { t } = useTranslation();
	const {
		data: {
			amounts,
		},
	} = notification;

	const amount = useMemo(() => amounts?.piPoints || 0, [amounts]);
	const amountChips = useMemo(() => amounts?.chips || 0, [amounts]);

	const donationMessageTranslationKey = useMemo(() => {
		if (amount && amountChips) return 'Toast.Donation.donatedCombinedAmount';
		if (amount) return 'Toast.Donation.donatedPiAmount';
		return 'Toast.Donation.donatedChipsAmount';
	}, [amount, amountChips]);

	return t(donationMessageTranslationKey, { amount, amountChips, systemCurrencyLabel });
};

NotificationToastDonation.propTypes = {
	notification: PropTypes.shape({
		data: PropTypes.shape({
			amounts: PropTypes.shape({
				piPoints: PropTypes.number,
				chips: PropTypes.number,
			}),
		}).isRequired,
	}).isRequired,
};

export default NotificationToastDonation;
