export const MembershipModalTab = {
	MEMBERSHIP: 'MEMBERSHIP',
	PRICING: 'PRICING',
	ORGANIZATION: 'ORGANIZATION',
	CUSTOM_PLAN: 'CUSTOM_PLAN',
	BILLING: 'BILLING',
	TRANSACTIONS: 'TRANSACTIONS',
	CHECKOUT: 'CHECKOUT',
	BUY_POINTS: 'BUY_POINTS',
	BUY_RESOURCE: 'BUY_RESOURCE',
	BUY_AD_CREDITS: 'BUY_AD_CREDITS',
	CASH_OUTS: 'CASH_OUTS',
	PURCHASE: 'PURCHASE',
};
