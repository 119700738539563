import { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'reactstrap';
import { useAsyncCallback } from 'react-async-hook';
import { useTranslation, Trans } from 'react-i18next';

import { ButtonPill, ButtonPillOutline } from '../Button/Pill';
import { ButtonLoading } from '../Button/Loading';
import { useAsyncErrorLog } from '../../lib/hooks';
import * as resourceAccessApi from '../../api/resourceAccess';
import * as accessApi from '../../api/access/access';
import { ResourceAccessCard } from './Card';
import buyVodImage from '../../assets/images/buy_vod.svg';
import buyVodSuccessImage from '../../assets/images/buy_vod_success.svg';
import { UserSettingsModal, useUserSettings } from '../UserSettings/Context';
import { MembershipModalTab } from '../UserSettings/MembershipModalTab';
import { ResourceAccessRole } from '../../lib/ResourceAccessRole';
import { getRoleField } from './helper';
import { Currency, currencyLabel, systemCurrencyLabel } from '../../lib/currency';
import { ResourceAccessPriceModal } from './PriceModal';
import { convertToUsd } from '../../lib/prices';

const ResourceAccessBuyStep = {
	BUY: 'BUY',
	INIT: 'INIT',
	INSUFFICIENT_FUNDS: 'INSUFFICIENT_FUNDS',
	SUCCESS: 'SUCCESS',
};

const PropTypesResourceData = PropTypes.shape({
	owner: PropTypes.shape({
		_id: PropTypes.string.isRequired,
		nickname: PropTypes.string,
	}).isRequired,
	price: PropTypes.shape({
		participant: PropTypes.number,
		viewer: PropTypes.number,
	}),
	presale: PropTypes.shape({
		participant: PropTypes.bool,
		viewer: PropTypes.bool,
	}),
	currencies: PropTypes.arrayOf(PropTypes.string),
	vodPrice: PropTypes.number,
	startAt: PropTypes.string,
});

const PropTypesResourceType = PropTypes.oneOf(['VIDEO', 'STUDIO']);

const getStudioLabelByRole = (role, t) => (role === ResourceAccessRole.VIEWER ? t('RessourceAccess.Buy.live') : t('RessourceAccess.Buy.meeting'));

export const getResourceCurrencyLabel = (currencies) => {
	if (currencies.includes(Currency.PI_POINTS)
		&& currencies.includes(Currency.CHIPS)
	) {
		return `${systemCurrencyLabel} or ${currencyLabel}`;
	} if (currencies.includes(Currency.PI_POINTS)) {
		return currencyLabel;
	}

	return 'chip';
};

const ResourceAccessBuyStepInit = ({
	onConfirm,
	resourceData,
	resourceType,
	role,
}) => {
	const { t } = useTranslation();
	let buyLabel = t('RessourceAccess.Buy.buy');
	let label = t('RessourceAccess.Buy.resource');
	let ownerLabel = t('RessourceAccess.Buy.owner');
	let price = 0;
	let currencies = [];

	const isPresale = useMemo(() => resourceType === 'STUDIO'
		&& !!resourceData.presale
		&& resourceData.presale[getRoleField(role)]
		&& Date.now() < Date.parse(resourceData.startAt),
	[resourceData, resourceType, role]);

	if (resourceType === 'STUDIO') {
		buyLabel = isPresale
			? t('RessourceAccess.Buy.studio.buyTicket')
			: t('RessourceAccess.Buy.studio.buySession');
		label = getStudioLabelByRole(role, t);
		ownerLabel = t('RessourceAccess.Buy.studio.ownerLabel');
		price = resourceData.price[getRoleField(role)];
		currencies = resourceData.currencies[getRoleField(role)];
	} else if (resourceType === 'VIDEO') {
		buyLabel = t('RessourceAccess.Buy.video.buyLabel');
		label = t('RessourceAccess.Buy.video.label');
		ownerLabel = t('RessourceAccess.Buy.video.ownerLabel');
		price = resourceData.vodPrice;
		currencies = resourceData.currencies;
	}

	return (
		<>
			<div>
				<img alt={t('RessourceAccess.Buy.buy')} src={buyVodImage} width="60" />
			</div>
			<p className="mt-3 font-weight-bold mb-0">
				<span className="text-info">{resourceData?.owner?.nickname} </span>
				<Trans i18nKey="RessourceAccess.Buy.description.charge" price={price} currency={getResourceCurrencyLabel(currencies)}>
					charges a {{ price: convertToUsd(price) }} {{ currency: getResourceCurrencyLabel(currencies) }} fee to watch.
				</Trans>
			</p>
			{isPresale && (
				<p className="mt-3 font-weight-bold mb-0">
					You can purchase ticket for this {label},
					your points will be returned if the {ownerLabel} cancels the {label}
				</p>
			)}
			{resourceType === 'VIDEO' && (
				<p className="font-size-sm mb-0">
					{t('RessourceAccess.Buy.description.rental')}
				</p>
			)}
			{resourceType === 'VIDEO'
				|| isPresale
				|| Date.now() > Date.parse(resourceData.startAt) ? (
					<footer className="mt-4 mx-auto d-flex">
						<ButtonPill
							color="white"
							className="mx-auto shadow-none"
							onClick={onConfirm}
						>
							{buyLabel}
						</ButtonPill>
					</footer>
				) : (
					<p className="mt-3 font-weight-bold mb-0">
						You can purchase the ticket for this {label} once it starts
					</p>
				)}
		</>
	);
};

ResourceAccessBuyStepInit.propTypes = {
	onConfirm: PropTypes.func.isRequired,
	resourceData: PropTypesResourceData.isRequired,
	resourceType: PropTypesResourceType.isRequired,
	role: PropTypes.oneOf(Object.values(ResourceAccessRole)).isRequired,
};

const ResourceAccessBuyStepBuy = ({
	onCancel,
	onInsufficientFunds,
	onSuccess,
	resourceData,
	resourceId,
	resourceType,
	role,
	refetchVideo,
}) => {
	const [openPriceModal, setOpenPriceModal] = useState(false);
	const { openModal, selectResourceToBuy, onResourcePurchaseSuccess } = useUserSettings();
	const { t } = useTranslation();
	let label = t('RessourceAccess.Buy.buy');
	let price = 0;
	let currencies = [];

	if (resourceType === 'STUDIO') {
		label = `${t('RessourceAccess.Buy.buyAccess')} ${getStudioLabelByRole(role, t)}`;
		price = resourceData.price[getRoleField(role)];
		currencies = resourceData.currencies[getRoleField(role)];
	} else if (resourceType === 'VIDEO') {
		label = t('RessourceAccess.Buy.rentVideo');
		price = resourceData.vodPrice;
		currencies = resourceData.currencies;
	}

	const { error, execute, loading } = useAsyncCallback(async (amounts) => {
		try {
			if (resourceType === 'VIDEO') {
				await accessApi.buyVideo(resourceId, amounts);
			} else {
				await resourceAccessApi.buy(resourceType, resourceId, role, amounts);
			}
			return onSuccess();
		} catch (err) {
			if (err?.response?.data?.reason === 'INSUFFICIENT_FUNDS') {
				onInsufficientFunds();
				return undefined;
			}
			throw err;
		}
	});

	const handleBuyWithChips = useCallback(() => {
		execute({
			chips: price,
			piPoints: 0,
		});
	}, [execute, price]);

	const handleBuyWithPi = useCallback(() => {
		execute({
			piPoints: price,
			chips: 0,
		});
	}, [execute, price]);

	const handleBuyWithCreditCard = useCallback(() => {
		selectResourceToBuy(resourceId, resourceType);
		openModal(UserSettingsModal.MEMBERSHIP, MembershipModalTab.CHECKOUT);

		onResourcePurchaseSuccess(() => () => refetchVideo());
	}, [
		onResourcePurchaseSuccess,
		openModal,
		refetchVideo,
		resourceId,
		resourceType,
		selectResourceToBuy,
	]);

	const handleBuyWithCombinedPoints = useCallback((amounts) => {
		execute(amounts);
	}, [execute]);

	useAsyncErrorLog({ error });

	const errorMessage = error?.response?.data?.message
		|| t('Global.error');

	return (
		<>
			<div>
				<img alt={t('RessourceAccess.Buy.buy')} src={buyVodImage} width="60" />
			</div>
			<p className="mt-3 font-weight-bold mb-0">
				{label} {t('RessourceAccess.Buy.for')} {convertToUsd(price)} {getResourceCurrencyLabel(currencies)}?
			</p>
			{resourceType === 'VIDEO' && (
				<p className="font-size-sm mb-0">
					{t('RessourceAccess.Buy.rentalActive')}
				</p>
			)}
			{error && (
				<Alert className="mt-3" color="danger">
					{errorMessage}
				</Alert>
			)}
			<footer className="mt-4 mx-auto d-flex">
				<ButtonPillOutline
					color="white"
					className="mr-2 shadow-none"
					onClick={onCancel}
				>
					{t('RessourceAccess.Buy.cancel')}
				</ButtonPillOutline>
				<ButtonLoading
					className="ml-auto shadow-none"
					color="white"
					component={ButtonPill}
					loading={loading}
					onClick={handleBuyWithCreditCard}
				>
					{resourceType === 'VIDEO' ? t('RessourceAccess.Buy.toRent') : t('RessourceAccess.Buy.toBuy')} {t('RessourceAccess.Buy.withCreditCard')}
				</ButtonLoading>
				{!!currencies?.includes(Currency.PI_POINTS) && (
					<ButtonLoading
						className="ml-auto shadow-none"
						color="white"
						component={ButtonPill}
						loading={loading}
						onClick={handleBuyWithPi}
					>
						{resourceType === 'VIDEO' ? t('RessourceAccess.Buy.toRent') : t('RessourceAccess.Buy.toBuy')} {t('RessourceAccess.Buy.withPoints', { currencyLabel })}
					</ButtonLoading>
				)}
				{!!currencies?.includes(Currency.CHIPS) && (
					<ButtonLoading
						className="ml-auto shadow-none"
						color="white"
						component={ButtonPill}
						loading={loading}
						onClick={handleBuyWithChips}
					>
						{resourceType === 'VIDEO' ? t('RessourceAccess.Buy.toRent') : t('RessourceAccess.Buy.toBuy')} {t('RessourceAccess.Buy.withChips', { systemCurrencyLabel })}
					</ButtonLoading>
				)}
				{!!currencies?.includes(Currency.CHIPS)
					&& !!currencies?.includes(Currency.PI_POINTS) && (
					<ButtonLoading
						className="ml-auto shadow-none"
						color="white"
						component={ButtonPill}
						loading={loading}
						onClick={() => setOpenPriceModal(true)}
					>
						{resourceType === 'VIDEO' ? t('RessourceAccess.Buy.toRent') : t('RessourceAccess.Buy.toBuy')} {t('RessourceAccess.Buy.withCombinedPoints')}
					</ButtonLoading>
				)}
			</footer>
			<ResourceAccessPriceModal
				isOpen={openPriceModal}
				closeModal={() => setOpenPriceModal(false)}
				price={price}
				onBuy={handleBuyWithCombinedPoints}
			/>
		</>
	);
};

ResourceAccessBuyStepBuy.propTypes = {
	onCancel: PropTypes.func.isRequired,
	onInsufficientFunds: PropTypes.func.isRequired,
	onSuccess: PropTypes.func.isRequired,
	resourceData: PropTypesResourceData.isRequired,
	resourceId: PropTypes.string.isRequired,
	resourceType: PropTypesResourceType.isRequired,
	role: PropTypes.oneOf(Object.values(ResourceAccessRole)).isRequired,
	refetchVideo: PropTypes.func,
};

ResourceAccessBuyStepBuy.defaultProps = {
	refetchVideo: undefined,
};

const ResourceAccessBuyStepInsufficientFunds = ({
	onCancel,
	resourceType,
	resourceData,
	role,
}) => {
	const { t } = useTranslation();
	let label = t('RessourceAccess.Buy.acessTheResource');
	const { openModal } = useUserSettings();

	const currencies = useMemo(() => (resourceType === 'VIDEO'
		? resourceData.currencies
		: resourceData.currencies[getRoleField(role)]
	), [resourceData, resourceType, role]);

	const isChipsOnly = useMemo(() => (
		currencies?.length === 1 && currencies.includes(Currency.CHIPS)
	), [currencies]);

	if (resourceType === 'STUDIO') {
		label = `${t('RessourceAccess.Buy.joinThis')} ${getStudioLabelByRole(role, t)}`;
	} else if (resourceType === 'VIDEO') {
		label = t('RessourceAccess.Buy.rentThisVideo');
	}

	return (
		<>
			<div>
				<img alt={t('RessourceAccess.Buy.buy')} src={buyVodImage} width="60" />
			</div>
			<p className="mt-3 font-weight-bold mb-0">
				{isChipsOnly ? (
					<span>{t('RessourceAccess.Buy.insufficientChips', { systemCurrencyLabel })}.</span>
				) : (
					<span>
						{t('RessourceAccess.Buy.purchase')}{' '}
						{label}.
					</span>
				)}
			</p>
			<footer className="mt-4 mx-auto d-flex">
				<ButtonPillOutline
					color="white"
					className="mr-2 shadow-none"
					onClick={onCancel}
				>
					{t('RessourceAccess.Buy.cancel')}
				</ButtonPillOutline>
				{!isChipsOnly && (
					<ButtonPill
						className="ml-auto shadow-none"
						color="white"
						onClick={() => openModal(UserSettingsModal.MEMBERSHIP, MembershipModalTab.BUY_POINTS)}
					>
						{t('RessourceAccess.Buy.purchasePoints')}
					</ButtonPill>
				)}
			</footer>
		</>
	);
};

ResourceAccessBuyStepInsufficientFunds.propTypes = {
	onCancel: PropTypes.func.isRequired,
	resourceData: PropTypesResourceData.isRequired,
	resourceType: PropTypesResourceType.isRequired,
	role: PropTypes.oneOf(Object.values(ResourceAccessRole)).isRequired,
};

const ResourceAccessBuyStepSuccess = ({
	onConfirm,
	resourceType,
	role,
}) => {
	const { t } = useTranslation();
	let label = t('RessourceAccess.Buy.acessTheResource');
	let resourceLabel = t('RessourceAccess.Buy.resource');

	if (resourceType === 'STUDIO') {
		label = `${t('RessourceAccess.Buy.joinThe')} ${getStudioLabelByRole(role, t)}`;
		resourceLabel = getStudioLabelByRole(role, t);
	} else if (resourceType === 'VIDEO') {
		label = t('RessourceAccess.Buy.watchTheVideo');
		resourceLabel = t('RessourceAccess.Buy.video');
	}

	return (
		<>
			<div>
				<img alt={t('RessourceAccess.Buy.sucess')} src={buyVodSuccessImage} width="60" />
			</div>
			<p className="mt-3 font-weight-bold mb-0">
				{t('RessourceAccess.Buy.congratulationsYouCan')}{' '}
				{label}!
			</p>
			{resourceType === 'VIDEO' && (
				<p className="font-size-sm mb-0">
					{t('RessourceAccess.Buy.rentalActive')}
				</p>
			)}
			<footer className="mt-4 mx-auto d-flex">
				<ButtonPill
					color="white"
					className="mx-auto shadow-none"
					onClick={onConfirm}
				>
					{t('RessourceAccess.Buy.continueTo')}{' '}{resourceLabel}
				</ButtonPill>
			</footer>
		</>
	);
};

ResourceAccessBuyStepSuccess.propTypes = {
	onConfirm: PropTypes.func.isRequired,
	resourceType: PropTypesResourceType.isRequired,
	role: PropTypes.oneOf(Object.values(ResourceAccessRole)).isRequired,
};

export const ResourceAccessBuy = ({
	resourceData,
	resourceType,
	resourceId,
	role,
	onSuccess,
	refetchVideo,
}) => {
	const [step, setStep] = useState(ResourceAccessBuyStep.INIT);

	return (
		<ResourceAccessCard className="bg-primary">
			{step === ResourceAccessBuyStep.INIT && (
				<ResourceAccessBuyStepInit
					onConfirm={() => setStep(ResourceAccessBuyStep.BUY)}
					resourceData={resourceData}
					resourceType={resourceType}
					role={role}
				/>
			)}
			{step === ResourceAccessBuyStep.BUY && (
				<ResourceAccessBuyStepBuy
					onCancel={() => setStep(ResourceAccessBuyStep.INIT)}
					onInsufficientFunds={() => setStep(ResourceAccessBuyStep.INSUFFICIENT_FUNDS)}
					onSuccess={() => setStep(ResourceAccessBuyStep.SUCCESS)}
					resourceData={resourceData}
					resourceId={resourceId}
					resourceType={resourceType}
					role={role}
					refetchVideo={refetchVideo}
				/>
			)}
			{step === ResourceAccessBuyStep.INSUFFICIENT_FUNDS && (
				<ResourceAccessBuyStepInsufficientFunds
					onCancel={() => setStep(ResourceAccessBuyStep.INIT)}
					resourceData={resourceData}
					resourceType={resourceType}
					role={role}
				/>
			)}
			{step === ResourceAccessBuyStep.SUCCESS && (
				<ResourceAccessBuyStepSuccess
					onConfirm={onSuccess}
					resourceData={resourceData}
					resourceType={resourceType}
					role={role}
				/>
			)}
		</ResourceAccessCard>
	);
};

ResourceAccessBuy.propTypes = {
	onSuccess: PropTypes.func.isRequired,
	resourceData: PropTypesResourceData.isRequired,
	resourceId: PropTypes.string.isRequired,
	resourceType: PropTypesResourceType.isRequired,
	role: PropTypes.oneOf(Object.values(ResourceAccessRole)).isRequired,
	refetchVideo: PropTypes.func,
};

ResourceAccessBuy.defaultProps = {
	refetchVideo: undefined,
};
